import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { useNavigate, Link } from "react-router-dom";
import { Button } from '@mui/material'


const AccountMenu =({ user,setUser }) =>{
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {
            user._id? 
              <Avatar style={{ backgroundColor: '#6D8FAB' }} sx={{ width: 32, height: 32 }}>{user.FirstName.split('')[0]}</Avatar>
              :
              <b style={{color:'#6D8FAB'}}  onClick={() => {setUser(); navigate("/Login")}}>Login</b>
            }
          </IconButton>
        </Tooltip>
      </Box>
      {
            user._id? 
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
         <MenuItem>
          <Link style={{ color: "#6D8FAB" }} className='btn' to={'/Account'}>
            <div className='row'> 
              <div className='col-1'><Avatar /></div>
              <div style={{alignSelf:'center'}} className='col'>{user.FirstName+ " " + user.LastName}</div>
            </div>  </Link>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
            <Button style={{ color: "#6D8FAB" }} onClick={() => {setUser(); sessionStorage.removeItem('"ms_id');}}><b style={{color:'#6D8FAB'}}>Logout</b></Button>
        </MenuItem> 
      </Menu>:""}
    </React.Fragment>
  );
}

export default AccountMenu;